import React, { PureComponent } from 'react'
import Helmet from 'react-helmet'
import gql from 'graphql-tag'
import styled from 'styled-components'
import { Query, withApollo } from 'react-apollo'
import { Redirect } from '@reach/router'
import { withAppContext } from '../contexts/AppContext'

import Spinner from '../components/Spinner'
import Layout from '../components/Layout'
import Section from '../components/Section'

import { MdKeyboardArrowRight } from 'react-icons/md'
import BlackSpinner from '../components/BlackSpinner'

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #009fe3;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

class Purl extends PureComponent {
  state = {
    shouldRedirect: false,
    purl: '',
    code: '',
    manualCodeInput: false,
    checkCodeInProcess: false,
    wrongCode: false,
  }

  componentDidMount() {
    const shouldRedirect = !!this.props.appContext.purl

    const [ignore, purl, code] = this.props['*'].split('/')

    this.setState({
      shouldRedirect,
      purl,
      ...(code && { code }),
      manualCodeInput: !code,
    })
  }

  componentDidUpdate() {
    const shouldRedirect = !!this.props.appContext.purl

    if (!this.state.shouldRedirect && shouldRedirect) {
      this.setState({ shouldRedirect })
    }
  }

  _onCompleted = data => {
    this.props.appContext.setPurl(data.purl)
  }

  checkCode = e => {
    e.preventDefault()

    this.setState({
      checkCodeInProcess: true,
    })

    this.props.client
      .query({
        query: PURL_QUERY,
        variables: { purl: this.state.purl, code: this.state.code },
      })
      .then(res => {
        this._onCompleted(res.data)
      })
      .catch(err => {
        this.setState({
          wrongCode: err.message.includes('UNAUTHORIZED'),
          checkCodeInProcess: false,
        })
      })
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value.toUpperCase() })
  }

  handleKeypress = event => {
    if (
      event.which === 48 || // 0
      event.which === 49 || // 1
      event.which === 73 || // I
      event.which === 76 || // L
      event.which === 79 || // O
      event.which === 105 || // i
      event.which === 108 || // l
      event.which === 111 // o
    ) {
      event.preventDefault()
      return false
    }
  }

  render() {
    if (this.state.shouldRedirect) return <Redirect to="/" noThrow />

    if (this.state.manualCodeInput) {
      return (
        <Layout withoutMenu>
          <Helmet>
            <title>persönlichen Code eingebene</title>
          </Helmet>

          <Section variant="blue">
            <p>Geben Sie jetzt Ihren persönlichen Code ein.</p>
            <Form onSubmit={this.checkCode}>
              <input
                required
                autoFocus
                name="code"
                maxLength="6"
                className={this.state.wrongCode ? 'has-errors' : ''}
                placeholder="Bitte geben Sie Ihren Code ein"
                value={this.state.code}
                onChange={this.handleChange}
                onKeyPress={this.handleKeypress}
              />

              <button disabled={this.state.code.length < 6}>
                {this.state.checkCodeInProcess ? (
                  <BlackSpinner />
                ) : (
                  <MdKeyboardArrowRight />
                )}
              </button>
            </Form>
          </Section>
        </Layout>
      )
    }

    return (
      <Query
        query={PURL_QUERY}
        variables={{ purl: this.state.purl, code: this.state.code }}
        onCompleted={this._onCompleted}
      >
        {({ loading, error, data }) => {
          if (error) {
            this.setState({ wrongCode: true })
          }

          return (
            <Wrapper>
              {loading && <Spinner />}

              {error && <h1>Oops</h1>}

              {!loading && !error && data && <Spinner />}
            </Wrapper>
          )
        }}
      </Query>
    )
  }
}

const PURL_QUERY = gql`
  query GetPurl($purl: String!, $code: String!) {
    purl(purl: $purl, code: $code) {
      purl
      code
      hash
      campaign {
        _id
        name
      }
      contact {
        companyName
        salutation
        firstName
        lastName
        email
      }
    }
  }
`

const Form = styled.form`
  position: relative;
  display: flex;
  align-items: center;
  width: 365px;
  margin: 0 auto;

  @media (max-width: 445px) {
    width: 100%;
  }

  input {
    flex-grow: 1;
    flex-shrink: 0;
    width: 100%;
    padding: 20px 70px 20px 20px;
    border-radius: 50px;
    border-color: transparent;
    font-weight: 300;
    font-size: 20px;

    &.has-errors {
      border-color: red;
    }
  }

  button {
    position: absolute;
    width: 44px;
    height: 44px;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 8px;
    border-radius: 100%;

    svg {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 30px;
      max-width: 30px;
      height: 30px;
      overflow: hidden;
    }
  }
`

export default withApollo(withAppContext(Purl))
