import React from 'react'
import styled, { keyframes } from 'styled-components'

const clockwise = keyframes`
  0% {
    transform: rotate(0deg)
  }

  100% {
    transform: rotate(360deg)
  }
`

const rotate = keyframes`
  0% {
    transform: rotate(0)
  }

  50% {
    transform: rotate(-140deg)
  }

  100% {
    transform: rotate(0)
  }
`

const Wrapper = styled.div`
  position: absolute;
  width: 50px;
  height: 50px;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  animation: ${clockwise} 500ms linear infinite;
`

const Cut = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 25px;
  height: 50px;
  overflow: hidden;
`

const Donut = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  margin: 0;
  background: none;
  border: 3px solid #fff;
  border-radius: 50%;
  border-left-color: transparent;
  border-bottom-color: transparent;
  box-sizing: border-box;
  animation: ${rotate} 500ms linear infinite;
`

const Spinner = () => (
  <Wrapper>
    <Cut>
      <Donut />
    </Cut>
  </Wrapper>
)

export default styled(Spinner)``
